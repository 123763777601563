// 
// authentication.scss
//

.auth-logo{
    .logo{
        margin: 0px auto;
    }

    .logo-dark{
        display: $display-block;
    }

    .logo-light{
        display: $display-none;
    }
}

.auth-bg{
    background-color: rgba(var(--bs-primary-rgb), 1);
    min-height: 100vh;
    background-size: cover;
    background-position: center;
}

.auth-logo-section{
    display: flex;
    flex-direction: column;
    @media (min-width: 992px) {
        height: 100vh;
    }

    @media (max-width: 991.98px) {
        text-align: center
    }
}

.authentication-page-content{
    height: calc(100% - 48px);
    background-color: var(--#{$prefix}secondary-bg);
    border-radius: 16px;
    margin: 24px;
}

.auth-img{
    position: relative;
    @media (min-width: 992px) and (max-width: 1549.98px) {
        max-width: 220%;
    }
    @media (min-width: 1550px) {
        max-width: 200%;
    }
    @media (max-width: 991.98px) {
        display: none;
    }
}

.logo-img{
    position: relative;
    @media (min-width: 992px) and (max-width: 1549.98px) {
        max-width: 60%;
      margin-bottom: 10px;
    }
    @media (min-width: 1550px) {
        max-width: 50%;
        margin-bottom: 9px;
    }
    @media (max-width: 991.98px) {
       max-width: 50%;
      margin-bottom: 10px;
        //display: none;
    }
}
// auth-pass-inputgroup

.auth-pass-inputgroup{
    input[type="text"] + .btn .ri-eye-fill{
        &:before{
            content: "\ec80";
        }
    }
}


// signin card title

.signin-other-title{
    position: relative;
    &:after{
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        left: 0;
        right: 0;
        background-color: var(--#{$prefix}border-color);
        top: 10px;
    }

    .title{
        display: inline-block;
        position: relative;
        z-index: 9;
        background-color: var(--#{$prefix}secondary-bg);
        padding: 2px 16px;
    }
}
